import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import cittadinoImg from "../assets/img/cittadino.jpg";
import aziendaImg from "../assets/img/azienda.jpg";
import CustomButtonBg from "../components/CustomButtonBg";

import { SurveyContext } from "../context/SurveyContextProvider";

export default function Category() {
  const navigate = useNavigate();
  const { type, setCategory } = useContext(SurveyContext);

  return (
    <div id="Survey">
      <h1 className="title">A che categoria appartieni?</h1>
      <CustomButtonBg
        text="Aziende"
        image={aziendaImg}
        onPress={() => {
          setCategory("azienda");
          navigate(`/survey/azienda/${type}`);
        }}
      />
      <CustomButtonBg
        text="Cittadini"
        image={cittadinoImg}
        onPress={() => {
          setCategory("cittadino");
          navigate(`/survey/cittadino/${type}`);
        }}
      />
    </div>
  );
}
