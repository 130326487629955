import React from "react";

export default function Grazie() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        backgroundImage: "linear-gradient(#1DE9B6, #0097A7)",
      }}
    >
      <p style={{ color: "white", fontSize: "2em", textAlign: "center" }}>
        Grazie per aver partecipato a questa attività
      </p>
    </div>
  );
}
