import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";

import simulationRequest from "../utils/simulationRequest";
import "./Simulation.css";

export default function Simulation() {
  const [user, setUser] = useState("");
  const [sID, setSID] = useState();
  const [amico, setAmico] = useState();
  const [errMsg, setErrMsg] = useState("");
  const [userType, setUserType] = useState("");
  const [serverReply, setServerReply] = useState({});
  const [acceptedGDPR, setAcceptedGDPR] = useState(false);
  const [email, setEmail] = useState();

  const costoEn = 0.3; //costo energia al kWh
  const RID = 0.04; //ricavo per ritiro dedicato

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      //console.log(`trovato token ${token}`)
      //check expiry date and, if successful, request user data
      const { exp, username, sessionID, amico } = jwt_decode(token);
      //console.log(exp, username, sessionID);
      if (Date.now() >= exp * 1000) {
        setErrMsg("Sessione scaduta");
        //localStorage.removeItem('token')
      } else {
        setUser(username);
        setAmico(amico);
        setSID(sessionID);

        //fetch user data
        simulationRequest(
          { username, sessionID },
          "individuale",
          setServerReply
        );
        /*dentro serverReply.data c'è un oggetto con questi campi:
        {
          prodTot
          consTot
          autoconsTot,
          immessaInRete,
          scambioProd,
          incentivoProd,
          acquistataDaRete,
          scambioCons,
          incentivoCons
        }*/
      }
    }
  }, []); //only run once, at first render

  const handleSendEmail = async (event) => {
    event.preventDefault();
    //console.log(username);
    if (!user) {
      setErrMsg("Utente non riconosciuto");
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/setEmail`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              username: user.trim(),
              sessionID: Number(sID),
              email: email,
            }),
          }
        );

        const data = await response.json();
        if (data.success) {
          alert("Grazie per aver inviato la tua mail");
        } else {
          setErrMsg(data.message);
        }
      } catch (e) {
        if (!e?.response) setErrMsg("Il server non risponde. Prova più tardi");
      }
    }
  };

  return (
    <div style={{ padding: "10px 20px" }}>
      {errMsg && <p>{errMsg}</p>}
      <h2>Ciao {user}!</h2>
      <h3>Ecco i risultati relativi alla tua utenza</h3>
      <div id="global_result_container">
        <p>
          Partecipare alla CER ti ha generato un incentivo di{" "}
          <span className="emph-text">
            {(serverReply.incentivoCons + serverReply.incentivoProd).toFixed(2)}{" "}
            €
          </span>
          *
          {serverReply.acquistataDaRete > 0 && (
            <>
              <br />
              pari al{" "}
              <span className="emph-text">
                {(
                  ((serverReply.incentivoCons + serverReply.incentivoProd) *
                    100) /
                  (serverReply.acquistataDaRete * costoEn)
                ).toFixed(1)}
                %
              </span>{" "}
              della tua spesa annua per l'energia.
            </>
          )}
        </p>
        <p className="small-text">
          * Questa stima ipotizza un costo della materia energia di {costoEn}€
          al kWh e un ricavo di {RID}€ al kWh per il ritiro dedicato per tutta
          l'energia immessa in rete.
        </p>
      </div>
      {serverReply.prodTot > 0 && (
        <div id="prod_container">
          <p>
            Totale energia prodotta:{" "}
            <span className="italic-text">
              {serverReply.prodTot.toFixed(0)} kWh
            </span>
            <br /> di cui:
          </p>
          <div className="prod-lev1_container">
            <p>
              <span className="italic-text">
                {serverReply.autoconsTot.toFixed(0)} kWh
              </span>{" "}
              autoconsumata{" "}
            </p>
            <p>
              Genera un risparmio di{" "}
              <span className="italic-text">
                {(costoEn * serverReply.autoconsTot).toFixed(2)}€{" "}
              </span>{" "}
              per non aver dovuto acquistare energia dalla rete
            </p>
          </div>
          <div className="prod-lev1_container">
            <p>
              <span className="italic-text">
                {serverReply.immessaInRete.toFixed(0)} kWh
              </span>{" "}
              immessi in rete{" "}
            </p>
            <p>
              Genera un ricavo di{" "}
              <span className="italic-text">
                {(serverReply.immessaInRete * RID).toFixed(2)}€
              </span>
              , se valorizzato con ritiro dedicato
            </p>
            <div className="prod-lev2_container">
              <p>
                Dell'energia immessa in rete
                <br />
                <span className="italic-text">
                  {serverReply.scambioProd.toFixed(0)} kWh
                </span>{" "}
                sono scambiati con consumatori della CER <br />
                che aggiunge un incentivo di{" "}
                <span className="emph-text">
                  {serverReply.incentivoProd.toFixed(2)}€{" "}
                </span>
                , secondo il regolamento approvato
              </p>
            </div>
          </div>
        </div>
      )}
      {serverReply.consTot > 0 && (
        <div id="cons_container">
          <p>
            Totale energia acquistata dalla rete:{" "}
            <span className="italic-text">
              {serverReply.acquistataDaRete.toFixed(0)} kWh
            </span>
            <br /> con un costo annuale stimato di{" "}
            <span className="italic-text">
              {(costoEn * serverReply.acquistataDaRete).toFixed(2)}€
            </span>
            <br />
            di cui:
          </p>
          <div className="cons-lev1_container">
            <p>
              <span className="italic-text">
                {serverReply.scambioCons.toFixed(0)} kWh
              </span>{" "}
              di "consumi buoni"
              <br />
              che hanno generato un incentivo annuo pari a{" "}
              <span className="emph-text">
                {serverReply.incentivoCons.toFixed(2)}€
              </span>
              , secondo il regolamento approvato nella CER
            </p>
          </div>
        </div>
      )}

      {/* Lascia la tua mail */}
      {amico && (
        <div style={{ paddingTop: "16px" }}>
          <p style={{ padding: 0, margin: 0 }}>
            Se vuoi restare aggiornato sulle ultime novità del mondo CER
            lasciaci la tua email
          </p>
          <div style={{ paddingTop: "12px", marginRight: "10px" }}>
            <input
              type="text"
              placeholder="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                width: "100%",
                height: "30px",
              }}
            />
          </div>
          <div style={{ paddingTop: "12px" }}>
            <input
              type="checkbox"
              checked={acceptedGDPR}
              onChange={() => {
                setAcceptedGDPR(!acceptedGDPR);
              }}
            />{" "}
            Ho letto e accetto la{" "}
            <a href={`${amico}Privacy.pdf`} target="_blank">
              privacy policy
            </a>
          </div>
          <div>
            <button
              onClick={handleSendEmail}
              className="submit"
              disabled={!acceptedGDPR || !email}
              style={
                acceptedGDPR && email
                  ? { backgroundColor: "#0961a0", cursor: "pointer" }
                  : { backgroundColor: "#ddd", cursor: "default" }
              }
            >
              Invia
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
