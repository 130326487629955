import { createContext, useState } from "react";
import { Outlet } from "react-router-dom";

export const SurveyContext = createContext();

export const SurveyContextProvider = ({ children }) => {
  const [category, setCategory] = useState(); //azienda o cittadino
  const [type, setType] = useState(); //consumer, producer o prosumer

  return (
    <SurveyContext.Provider
      value={{
        category,
        setCategory,
        type,
        setType,
      }}
    >
      {children ? children : <Outlet />}
    </SurveyContext.Provider>
  );
};
