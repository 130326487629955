import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import QuestionCheckbox from "../../components/QuestionCheckbox";
import QuestionRadio from "../../components/QuestionRadio";

//import { SurveyContext } from "../../context/SurveyContextProvider";

export default function SurveyProsumer() {
  //const { type, category } = useContext(SurveyContext);
  const type = "prosumer";
  const category = "cittadino";

  const months = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];
  const fasce = [
    "Lun-Ven mattina",
    "Lun-Ven pomeriggio",
    "Lun-Ven sera",
    "Lun-Ven notte",
    "Weekend mattina",
    "Weekend pomeriggio",
    "Weekend sera",
    "Weekend notte",
  ];

  const consumptions = ["single (1500 kWh)", "famiglia (3000 kWh)"];
  const consumi_suggeriti = [1500, 3000];

  const [prodUnits, setProdUnits] = useState("kW");
  const [prodValue, setProdValue] = useState("");
  const [q1, setQ1] = useState(new Array(months.length).fill(false));
  const [q2, setQ2] = useState(new Array(fasce.length).fill(false));
  const [q3, setQ3] = useState("");
  const [consKnown, setConsKnown] = useState("yes");
  const [conskWh, setConskWh] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    var entryCheck = false;

    if (prodValue && (q3 || conskWh)) entryCheck = true;

    if (q2.indexOf(true) === -1) entryCheck = false; //if none of the option in q2 was selected => entryCheck=false

    if (entryCheck === true) {
      const prodkW =
        prodUnits === "kW" ? Number(prodValue) : Number(prodValue) / 10;

      const annualConsumption =
        consKnown === "yes"
          ? conskWh
          : consumi_suggeriti[Number(q3.split("_")[1])];
      const survey = {
        type,
        category,
        prodkW,
        q1,
        q2,
        conskWh: Number(annualConsumption),
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/survey`,
          {
            method: "POST",
            headers: {
              "x-access-token": localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
            body: JSON.stringify(survey),
          }
        );

        const data = await response.json();
        if (data.success) {
          navigate("/grazie");
        } else {
          setErrMsg(data.message);
        }
      } catch (e) {
        if (!e?.response) setErrMsg("Il server non risponde. Prova più tardi");
      }
    } else {
      console.log("Riempire tutti i campi prima di continuare");
    }
  };

  return (
    <div id="Survey">
      <form onSubmit={handleSubmit}>
        <h3>
          Completa questo breve questionario per creare il tuo profilo
          energetico
        </h3>
        <div className="form-group">
          <p>
            Di che dimensione è il tuo impianto di produzione? (Seleziona se
            vuoi inserire i kW dell'impianto o i mq della superficie e poi
            inserisci il valore numerico nel campo di testo)
          </p>
          <input
            type="radio"
            checked={prodUnits === "kW"}
            id="kW"
            name="prodUnits"
            value="kW"
            onChange={(e) => setProdUnits(e.target.value)}
          />
          <label htmlFor="kW">kW</label>
          <input
            type="radio"
            checked={prodUnits === "mq"}
            id="mq"
            name="prodUnits"
            value="mq"
            onChange={(e) => setProdUnits(e.target.value)}
          />
          <label htmlFor="mq">mq</label>
          <div>
            <label htmlFor="prodValue">Dimensione impianto</label>
            <input
              type="number"
              min="1"
              max={prodUnits == "kW" ? "999" : "9990"}
              placeholder={prodUnits}
              id="prodValue"
              name="prodValue"
              value={prodValue}
              onChange={(e) => setProdValue(e.target.value)}
            />
          </div>
        </div>

        <QuestionCheckbox
          question="In quali mesi sei fuori casa per due o più settimane?"
          answers={months}
          name="q1"
          value={q1}
          setValue={setQ1}
        />

        <QuestionCheckbox
          question="Seleziona le fasce orarie in cui utilizzi di più gli elettrodomestici"
          answers={fasce}
          name="q2"
          value={q2}
          setValue={setQ2}
        />

        <div className="form-group">
          <p>
            Inserisci il tuo consumo annuo di energia elettrica (se lo conosci)
            o selezionane uno tra quelli suggeriti che meglio ti rappresenta
          </p>
          <input
            type="radio"
            id="known"
            name="cons"
            checked={consKnown === "yes"}
            value="yes"
            onChange={(e) => setConsKnown(e.target.value)}
          />
          <label htmlFor="known">Consumo noto</label>
          <input
            type="radio"
            id="unknown"
            name="cons"
            checked={consKnown === "no"}
            value="no"
            onChange={(e) => setConsKnown(e.target.value)}
          />
          <label htmlFor="unknown">suggerito</label>
          {consKnown === "yes" ? (
            <div>
              <label htmlFor="consumption">Consumo annuo</label>
              <input
                type="number"
                min="1"
                placeholder="kWh/anno"
                id="consumption"
                name="conskWh"
                value={conskWh}
                onChange={(e) => setConskWh(e.target.value)}
              />
            </div>
          ) : (
            <QuestionRadio
              question="Tipologia:"
              answers={consumptions}
              name="q3"
              setValue={setQ3}
            />
          )}
        </div>
        <input className="submit" type="submit" value="Invia le risposte" />
        {errMsg && <p>{errMsg}</p>}
      </form>
    </div>
  );
}
