import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

import QuestionRadio from "../components/QuestionRadio";

export default function Regolamento() {
  const percentuali = [
    "30% consumatori / 70% produttori",
    "40% consumatori / 60% produttori",
    "50% consumatori / 50% produttori",
    "60% consumatori / 40% produttori",
    "70% consumatori / 30% produttori",
  ];
  const distribuzione = [
    "proporzionale ai kWh prodotti (o consumati)",
    "equamente tra tutti i produttori (o consumatori) attivi, indipendentemente dalla quantità di kWh",
  ];

  const [q1, setQ1] = useState("");
  const [q2, setQ2] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [user, setUser] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      //console.log(`trovato token ${token}`)
      //check expiry date and, if successful, request user data
      const { exp, username, sessionID } = jwt_decode(token);
      //console.log(exp, username, sessionID);
      if (Date.now() >= exp * 1000) {
        setErrMsg("Sessione scaduta");
        //localStorage.removeItem('token')
      } else {
        setUser(username);
      }
    }
  }, []); //only run once, at first render

  const handleSubmit = async (event) => {
    event.preventDefault();
    var entryCheck = false;

    console.log({ q1, q2 });
    if (q1 && q2) entryCheck = true;

    if (entryCheck === true) {
      //console.log("entries OK");
      const survey = {
        q1,
        q2,
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/regolamento`,
          {
            method: "POST",
            headers: {
              "x-access-token": localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
            body: JSON.stringify(survey),
          }
        );

        const data = await response.json();
        if (data.success) {
          navigate("/grazie");
        } else {
          setErrMsg(data.message);
        }
      } catch (e) {
        if (!e?.response) setErrMsg("Il server non risponde. Prova più tardi");
      }
    } else {
      setErrMsg("Riempire tutti i campi prima di continuare");
      //console.log("Riempire tutti i campi prima di continuare");
    }
  };

  return (
    <div style={{ padding: "10px 20px" }}>
      {errMsg && <p>{errMsg}</p>}
      <h2>Ciao {user}!</h2>
      <h3>
        Adesso esprimi il tuo voto per decidere, insieme agli altri membri, il
        regolamento della CER per distribuire gli incentivi ottenuti
      </h3>
      <form onSubmit={handleSubmit}>
        <QuestionRadio
          question="Con che percentuale vuoi suddividere gli incentivi tra produttori e consumatori?"
          answers={percentuali}
          name="q1"
          setValue={setQ1}
        />

        <QuestionRadio
          question="In che modo vuoi distribuire gli incentivi fra gli utenti?"
          answers={distribuzione}
          name="q2"
          setValue={setQ2}
        />
        <input className="submit" type="submit" value="Invia le risposte" />
        {errMsg && <p>{errMsg}</p>}
      </form>
    </div>
  );
}
