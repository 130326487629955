async function simulationRequest(bodyReq, url, setServerReply) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/simulation/${url}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyReq),
      }
    );

    const data = await response.json();
    if (data.success) {
      setServerReply({ ...data.data });
      //calcolo risultati per il plot del grafico a torta
    } else {
      //return false
      console.log("ERRORE: Session ID non trovato nel DB");
    }
  } catch (e) {
    if (!e?.response) {
      //setErrMsg('Il server non risponde. Prova più tardi')
      console.log(`${e}: Il server non risponde. Prova più tardi`);
      //return false;
    }
  }
}

export default simulationRequest;
