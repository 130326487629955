import React from 'react'

export default function QuestionCheckbox({question, answers, name, value, setValue}) {

  const handleCheckboxChange = (checkedState, setCheckedState, position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    )
    setCheckedState(updatedCheckedState);
  }

  return (
    <div className="form-group">
      <p>{question}</p>
      {
        answers.map((item, index) => {
          return(
            <div key={index}>
              <input type="checkbox" id={item} name={name} checked={value[index]} onChange={() => handleCheckboxChange(value, setValue, index)}/>
              <label htmlFor={item}>{item}</label>
            </div>
          )
        })
      }
    </div>
  )
}
