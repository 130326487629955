import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

//import logo from "../assets/img/light_bulb.png";
import logo from "../assets/img/CERtrude.png";
import "./Home.css";

export default function Home() {
  const [username, setUsername] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [sID, setSessionID] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const url = new URL(window.location);
    const searchParams = url.searchParams;
    const urlSID = searchParams.get("sID");
    setSessionID(searchParams.get("sID"));

    //console.log(`URL-> sID = ${urlSID}`);
    const token = localStorage.getItem("token");
    if (token) {
      //console.log(`trovato token ${token}`);
      //check expiry date and, if successful, redirect to /survey
      const { exp, username, sessionID } = jwt_decode(token);
      //console.log(exp, username, sessionID);
      if (Date.now() >= exp * 1000 || sessionID != urlSID) {
        setErrMsg("Sessione scaduta");
        //localStorage.removeItem('token')
      } else {
        setErrMsg(
          `Sessione ancora attiva. Username: ${username}. Reindirizzamento in corso`
        );
        /*console.log(
          `Sessione ancora attiva. Username: ${username}. Reindirizzamento in corso`
        );*/
        navigate("/survey");
      }
    }
  }, [errMsg]); //only run once, at first render

  const handleSubmit = async (event) => {
    event.preventDefault();
    //console.log(username);
    if (!username) {
      setErrMsg("Scegliere uno username per continuare");
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/register`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              username: username.trim(),
              sessionID: Number(sID),
            }),
          }
        );

        const data = await response.json();
        if (data.success) {
          const token = data.token;
          //console.log('obtained token: ', token)
          localStorage.setItem("token", token);
          navigate("/survey");
        } else {
          setErrMsg(data.message);
        }
      } catch (e) {
        if (!e?.response) setErrMsg("Il server non risponde. Prova più tardi");
      }
    }
  };

  return (
    <div id="home">
      <h1 className="title">CERtrude ti dà il benvenuto!</h1>
      <img src={logo} alt="Logo Light" className="logo" />
      <p style={{ color: "white", paddingLeft: 20, paddingRight: 20 }}>
        {" "}
        Scegli uno username per partecipare al training
      </p>
      <form onSubmit={handleSubmit}>
        <label style={{ color: "white" }}>
          Username:
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            onFocus={() => setErrMsg("")}
          />
        </label>
        <input className="submit" type="submit" value="Inizia" />
      </form>
      {/*errMsg ? <p className="errText">{errMsg}</p> : <></>*/}
    </div>
  );
}
