import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import jwt_decode from "jwt-decode";

export default function ProtectedRoute({ redirectPath = "/", children }) {
  const [auth, setAuth] = useState();

  useEffect(() => {
    const isAuthorised = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        //console.log(`trovato token ${token}`)
        //check expiry date and, if successful, request user data
        const { exp, username, sessionID } = jwt_decode(token);
        //console.log(exp, username, sessionID);
        if (Date.now() >= exp * 1000) {
          console.log("Sessione scaduta");
          //return undefined;
        } else {
          //verify auth server-side
          try {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/api/auth`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ token }),
              }
            );

            const data = await response.json();
            console.log(data);
            setAuth(data.success);
          } catch (e) {
            if (!e?.response) {
              //setErrMsg('Il server non risponde. Prova più tardi')
              console.log(`${e}: Il server non risponde. Prova più tardi`);
              //return false;
            }
          }
        }
      } else {
        console.log("Nessuna sessione presente");
        //return undefined;
      }
    };

    isAuthorised();
  }, [auth]);

  if (auth === undefined) return null;

  if (!auth) {
    console.log("Not authorised");
    return <Navigate to={redirectPath} replace />;
  }
  console.log("Authorised");
  return children ? children : <Outlet />;
}
