import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

import consumerImg from "../assets/img/energy_consumer.jpg";
import producerImg from "../assets/img/energy_producer.jpg";
import prosumerImg from "../assets/img/energy_prosumer.jpg";
import CustomButtonBg from "../components/CustomButtonBg";

import { SurveyContext } from "../context/SurveyContextProvider";

import "./Survey.css";

export default function Survey() {
  const navigate = useNavigate();
  const { setType } = useContext(SurveyContext);
  const [user, setUser] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      //console.log(`trovato token ${token}`)
      //check expiry date and, if successful, request user data
      const { exp, username, sessionID } = jwt_decode(token);
      //console.log(exp, username, sessionID);
      if (Date.now() >= exp * 1000) {
        //setErrMsg("Sessione scaduta");
        //localStorage.removeItem('token')
        console.log("Sessione scaduta");
      } else {
        setUser(username);
      }
    }
  }, [user]); //only run once, at first render

  return (
    <div id="Survey">
      <h2> Ciao {user}</h2>
      <h1 className="title">Che tipo di utente sei?</h1>
      <p className="italic">
        Completa questo breve questionario per impostare il tuo profilo
        energetico
      </p>
      <CustomButtonBg
        text="Consumer"
        image={consumerImg}
        bgwidth="60%"
        onPress={() => {
          setType("consumer");
          navigate("/survey/category");
        }}
      />
      <CustomButtonBg
        text="Producer"
        image={producerImg}
        onPress={() => {
          setType("producer");
          navigate("/survey/category");
        }}
      />
      <CustomButtonBg
        text="Prosumer"
        image={prosumerImg}
        onPress={() => {
          setType("prosumer");
          navigate("/survey/category");
        }}
      />
    </div>
  );
}
