import { Routes, Route } from "react-router-dom";

import ProtectedRoute from "./components/ProtectedRoute";
import { SurveyContextProvider } from "./context/SurveyContextProvider";

import Home from "./pages/Home";
import Survey from "./pages/Survey";
import Category from "./pages/Category";
import Simulation from "./pages/Simulation";
import SurveyAziendaConsumer from "./pages/azienda/SurveyAziendaConsumer";
import SurveyAziendaProsumer from "./pages/azienda/SurveyAziendaProsumer";
import SurveyAziendaProducer from "./pages/azienda/SurveyAziendaProducer";
import SurveyCittadinoConsumer from "./pages/cittadino/SurveyCittadinoConsumer";
import SurveyCittadinoProsumer from "./pages/cittadino/SurveyCittadinoProsumer";
import SurveyCittadinoProducer from "./pages/cittadino/SurveyCittadinoProducer";
import Grazie from "./pages/Grazie";
import Regolamento from "./pages/Regolamento";

import "./App.css";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route element={<ProtectedRoute />}>
          <Route element={<SurveyContextProvider />}>
            <Route path="/survey" exact element={<Survey />} />
            <Route path="/survey/category" exact element={<Category />} />
            {/*azienda*/}
            <Route
              path="/survey/azienda/consumer"
              exact
              element={<SurveyAziendaConsumer />}
            />
            <Route
              path="/survey/azienda/producer"
              exact
              element={<SurveyAziendaProducer />}
            />
            <Route
              path="/survey/azienda/prosumer"
              exact
              element={<SurveyAziendaProsumer />}
            />
            {/*cittadino*/}
            <Route
              path="/survey/cittadino/consumer"
              exact
              element={<SurveyCittadinoConsumer />}
            />
            <Route
              path="/survey/cittadino/producer"
              exact
              element={<SurveyCittadinoProducer />}
            />
            <Route
              path="/survey/cittadino/prosumer"
              exact
              element={<SurveyCittadinoProsumer />}
            />
          </Route>
          <Route path="/risIndividuali" exact element={<Simulation />} />
          <Route path="/regolamentoCER" exact element={<Regolamento />} />
        </Route>
        <Route path="/grazie" exact element={<Grazie />} />
      </Routes>
    </div>
  );
}

export default App;
