import React from "react";

export default function CustomButtonBg({
  onPress = () => {
    console.log("cliccato");
  },
  text,
  image,
  bgwidth = "100%",
}) {
  return (
    <div
      onClick={onPress}
      style={{
        backgroundImage: `url(${image})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: bgwidth,
        backgroundPositionY: "center",
        backgroundPositionX: "center",
        width: "100%",
        maxWidth: 400,
        height: 120,
        borderRadius: 15,
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        paddingRight: 10,
        paddingBottom: 30,
        marginBottom: 10,
      }}
    >
      <p
        style={{
          fontSize: 16,
          fontWeight: 700,
          background: "rgba(255,255,255,0.75)",
          borderRadius: 4,
          padding: "8px 40px",
          margin: 0,
        }}
      >
        {text}
      </p>
    </div>
  );
}
