import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import QuestionCheckbox from "../../components/QuestionCheckbox";
import QuestionRadio from "../../components/QuestionRadio";

//import { SurveyContext } from "../../context/SurveyContextProvider";

export default function SurveyConsumer() {
  //const { type, category } = useContext(SurveyContext);
  const type = "consumer";
  const category = "azienda";

  const months = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];
  const days = [
    "Lunedì",
    "Martedì",
    "Mercoledì",
    "Giovedì",
    "Venerdì",
    "Sabato",
    "Domenica",
  ];
  const categories = ["Manifatturiera", "Servizi", "Artigianato-Commercio"];
  const energies = ["Solo elettricità", "Elettricità + Gas"];
  const times = [
    "h24",
    "8.00-18.00",
    "6.00-22.00",
    "9.00-13.00 e 15.00-19.00",
    "11.00-15.00 e 18.00-24.00",
  ];
  const consumptions = [
    "3000 kWh",
    "15000 kWh",
    "50000 kWh",
    "70000 kWh",
    "120000 kWh",
    "200000 kWh",
    "300000 kWh",
    "450000 kWh",
    "600000 kWh",
  ];

  const [q1, setQ1] = useState("");
  const [q2, setQ2] = useState("");
  const [q3, setQ3] = useState(new Array(months.length).fill(false));
  const [q4, setQ4] = useState(new Array(days.length).fill(false));
  const [q5, setQ5] = useState("");
  const [q6, setQ6] = useState("");
  const [consKnown, setConsKnown] = useState("yes");
  const [conskWh, setConskWh] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    var entryCheck = false;

    if (q1 && q2 && q5 && (q6 || conskWh)) entryCheck = true;

    if (entryCheck === true) {
      //console.log("entries OK");
      const annualConsumption =
        consKnown === "yes"
          ? conskWh
          : consumptions[Number(q6.split("_")[1])].split(" ")[0];
      const survey = {
        type,
        category,
        q1,
        q2,
        q3,
        q4,
        q5,
        conskWh: Number(annualConsumption),
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/survey`,
          {
            method: "POST",
            headers: {
              "x-access-token": localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
            body: JSON.stringify(survey),
          }
        );

        const data = await response.json();
        if (data.success) {
          navigate("/grazie");
        } else {
          setErrMsg(data.message);
        }
      } catch (e) {
        if (!e?.response) setErrMsg("Il server non risponde. Prova più tardi");
      }
    } else {
      setErrMsg("Riempire tutti i campi prima di continuare");
      //console.log("Riempire tutti i campi prima di continuare");
    }
  };

  return (
    <div id="Survey">
      <form onSubmit={handleSubmit}>
        <h3>
          Completa questo breve questionario per creare il tuo profilo
          energetico
        </h3>

        <QuestionRadio
          question="Che tipo di azienda sei?"
          answers={categories}
          name="q1"
          setValue={setQ1}
        />

        <QuestionRadio
          question="Quali consumi?"
          answers={energies}
          name="q2"
          setValue={setQ2}
        />

        <QuestionCheckbox
          question="In quali mesi l'azienda è chiusa per due o più settimane?"
          answers={months}
          name="q3"
          value={q3}
          setValue={setQ3}
        />

        <QuestionCheckbox
          question="Indica i giorni di chiusura settimanale"
          answers={days}
          name="q4"
          value={q4}
          setValue={setQ4}
        />

        <QuestionRadio
          question="Seleziona l'orario che meglio rappresenta la tua attività"
          answers={times}
          name="q5"
          setValue={setQ5}
        />

        <div className="form-group">
          <p>
            Inserisci il consumo annuo di energia elettrica della tua azienda
            (se lo conosci) o selezionane uno approssimato che meglio
            rappresenta la tua attività
          </p>
          <input
            type="radio"
            id="known"
            name="cons"
            checked={consKnown === "yes"}
            value="yes"
            onChange={(e) => setConsKnown(e.target.value)}
          />
          <label htmlFor="known">Consumo noto</label>
          <input
            type="radio"
            id="unknown"
            name="cons"
            checked={consKnown === "no"}
            value="no"
            onChange={(e) => setConsKnown(e.target.value)}
          />
          <label htmlFor="unknown">approssimato</label>
          {consKnown === "yes" ? (
            <div>
              <label htmlFor="consumption">Consumo annuo</label>
              <input
                type="number"
                min="1"
                placeholder="kWh/anno"
                id="consumption"
                name="conskWh"
                value={conskWh}
                onChange={(e) => setConskWh(e.target.value)}
              />
            </div>
          ) : (
            <QuestionRadio
              question="Consumo approssimato"
              answers={consumptions}
              name="q6"
              setValue={setQ6}
            />
          )}
        </div>
        <input className="submit" type="submit" value="Invia le risposte" />
        {errMsg && <p>{errMsg}</p>}
      </form>
    </div>
  );
}
