import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

//import { SurveyContext } from "../../context/SurveyContextProvider";

export default function SurveyProducer() {
  //const { type, category } = useContext(SurveyContext);
  const type = "producer";
  const category = "cittadino";

  const [prodUnits, setProdUnits] = useState("kW");
  const [prodValue, setProdValue] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    var entryCheck = false;

    if (prodValue) entryCheck = true;

    if (entryCheck === true) {
      console.log("entries OK");

      const prodkW =
        prodUnits === "kW" ? Number(prodValue) : Number(prodValue) / 10;

      const survey = {
        type,
        category,
        prodkW,
      };
      //console.log(survey);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/survey`,
          {
            method: "POST",
            headers: {
              "x-access-token": localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
            body: JSON.stringify(survey),
          }
        );

        const data = await response.json();
        if (data.success) {
          navigate("/grazie");
        } else {
          setErrMsg(data.message);
        }
      } catch (e) {
        if (!e?.response) setErrMsg("Il server non risponde. Prova più tardi");
      }
    } else {
      console.log("Riempire tutti i campi prima di continuare");
    }
  };

  return (
    <div id="Survey">
      <form onSubmit={handleSubmit}>
        <h3>
          Completa questo breve questionario per creare il tuo profilo
          energetico
        </h3>
        <div className="form-group">
          <p>
            Di che dimensione è il tuo impianto di produzione? (Seleziona se
            vuoi inserire i kW dell'impianto o i mq della superficie e poi
            inserisci il valore numerico nel campo di testo)
          </p>
          <input
            type="radio"
            checked={prodUnits === "kW"}
            id="kW"
            name="prodUnits"
            value="kW"
            onChange={(e) => setProdUnits(e.target.value)}
          />
          <label htmlFor="kW">kW</label>
          <input
            type="radio"
            checked={prodUnits === "mq"}
            id="mq"
            name="prodUnits"
            value="mq"
            onChange={(e) => setProdUnits(e.target.value)}
          />
          <label htmlFor="mq">mq</label>
          <div>
            <label htmlFor="prodValue">Dimensione impianto</label>
            <input
              type="number"
              min="1"
              max={prodUnits == "kW" ? "999" : "9990"}
              width={"50%"}
              placeholder={prodUnits}
              id="prodValue"
              name="prodValue"
              value={prodValue}
              onChange={(e) => setProdValue(e.target.value)}
            />
          </div>
        </div>

        {errMsg && <p style={{ color: "red" }}>{errMsg}</p>}
        <input className="submit" type="submit" value="Invia le risposte" />
      </form>
    </div>
  );
}
