import React from "react";

import "./Question.css";

export default function QuestionRadio({ question, answers, name, setValue }) {
  return (
    <div className="form-group">
      <p>{question}</p>
      {answers.map((item, index) => {
        return (
          <div key={index}>
            <input
              type="radio"
              id={`${name}_${index}`}
              name={name}
              value={`${name}_${index}`}
              onChange={(e) => setValue(e.target.value)}
            />
            <label htmlFor={`${name}_${index}`}>{item}</label>
          </div>
        );
      })}
    </div>
  );
}
